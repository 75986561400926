<template>
  <header class="te-header co-border-gray-300 fixed inset-x-0 top-0 border-b">
    <div class="co-container-lg navbar h-full py-0 pl-4 pr-0 lg:px-4">
      <div class="flex-1">
        <!-- ログイン中のロゴ -->
        <div v-if="currentUser">
          <TeCommonHeaderLogoSp class="sm:hidden" />
          <div class="hidden sm:block">
            <TeCommonHeaderLogo />
          </div>
        </div>
        <!-- 未ログインのロゴ -->
        <TeCommonHeaderLogo v-else />
      </div>

      <div class="flex-none">
        <!-- PCヘッダーメニュー -->
        <TheTeCommonHeaderMenuPc />

        <!-- ボタン -->
        <client-only>
          <!-- コース申込済 -->
          <a
            v-if="currentUser && paid"
            class="co-btn-secondary co-header-btn"
            href="/my"
          >
            学習する
            <FontAwesomeIcon
              :icon="faCircleUser"
              class="co-display-8 text-co-primary-400"
            />
          </a>

          <!-- 無料相談申込済 -->
          <a
            v-else-if="currentUser && !paid && appliedHtmlcssTrial"
            class="co-btn-secondary co-header-btn"
            href="/my"
          >
            無料相談へ
            <FontAwesomeIcon
              :icon="faCircleUser"
              class="co-display-8 text-co-primary-400"
            />
          </a>

          <!-- 未ログイン状態 -->
          <div
            v-else
            class="flex items-center"
          >
            <a
              href="/counseling/htmlcss-schedule"
              class="bot_open co-btn-counseling !px-3 !py-2"
            >無料相談</a>
            <a
              class="te-btn-login hidden px-4 text-center text-[0.875rem] text-co-gray-700 transition-all hover:text-co-gray-700 hover:brightness-95 lg:block"
              href="/my"
            >
              <FontAwesomeIcon
                :icon="faRightToBracket"
                class="co-display-9 mx-auto block"
              />
              ログイン
            </a>
          </div>
        </client-only>

        <!-- SPメニュー -->
        <TheTeCommonHeaderMenuSp />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleUser, faRightToBracket } from '@awesome.me/kit-65d95819ce/icons/classic/solid'
import { useUser } from '~/store/user'

import TeCommonHeaderLogoSp from '~/components/Te/TeCommon/Header/TeCommonHeaderLogoSp/TeCommonHeaderLogoSp.vue'
import TeCommonHeaderLogo from '~/components/Te/TeCommon/Header/TeCommonHeaderLogo/TeCommonHeaderLogo.vue'
import TheTeCommonHeaderMenuSp from '~/components/Te/TeCommon/Header/TheTeCommonHeaderMenuSp/TheTeCommonHeaderMenuSp.vue'
import TheTeCommonHeaderMenuPc from '~/components/Te/TeCommon/Header/TheTeCommonHeaderMenuPc/TheTeCommonHeaderMenuPc.vue'

const { currentUser, paid, appliedHtmlcssTrial } = storeToRefs(useUser())
</script>

<style scoped>
.te-header {
  z-index: 1030;
  height: 70px;
  @apply w-full bg-co-gray-50;
}

.te-header-btn {
  @apply px-3 py-2;
}

/* 無料相談ボタン */
.te-btn-free-trial {
  @apply rounded-full px-3 py-2;
  @media (max-width: 374px) {
    font-size: 0.9375rem;
  }
}

.co-header-btn {
  @apply px-3 py-2;
}
</style>
